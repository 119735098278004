import { useNative } from '@web/data/native.helper';
import IndexLayout from '@lp-root/src/components/org.layout/layout.component';
import { DigitalTreatment, FreeTreatment, linkTo199 } from '@lp-root/src/modules/subscribe/payment-options';
import SubscribePageTemplate from '@lp-root/src/modules/subscribe/subscribe-page-template';
import { PageProps } from '@lp-src/utils/local-types';
import { Col, Row } from '@web/atomic';
import { graphql } from 'gatsby';
import * as React from 'react';

const AssinaturaPage: React.FunctionComponent<PageProps> = (props) => {
  const native = useNative();
  return (
    <IndexLayout location={props.location}>
      <SubscribePageTemplate
        title={'Escolha o seu plano'}
        location={props.location}
        siteUrl={props.data.site.siteMetadata.siteUrl}
        // BUSINESS_RULE: this section should not appear on the native side, otherwise apple will reject the app in the review process
        hideFreeTrial={native}
      >
        <Row center="xs">
          {native || (
            <Col xs={12} sm={10} md={4} mb>
              <FreeTreatment />
            </Col>
          )}
          <Col xs={12} sm={10} md={4} mb>
            <DigitalTreatment to={linkTo199} price={'199,99'} />
          </Col>
        </Row>
      </SubscribePageTemplate>
    </IndexLayout>
  );
};

export default AssinaturaPage;

export const query = graphql`
  query AssinaturaQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
